/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss"

// if (process.env.NODE_ENV !== "development") {
//   console.log("not development")
// } else if (process.env.NODE_ENV === "development") {
//   console.log("is development")
// }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    window.ga(
      "set",
      "page",
      location.pathname + location.search + location.hash
    )
    window.ga("send", "pageview")
  }
}
